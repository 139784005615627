html {
  background-color: initial;
}

#root {
  font-family: var(--font-normal), sans-serif;
  background-color: var(--main-background-color);
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

h1,
h2,
h3,
h4,
h5,
h6,
.site-header {
  font-weight: 300;
}

.color-0 {
  color: #000 !important;
}

.color-1,
.primary {
  color: var(--primary-color) !important;
}

.color-3,
.grey-text {
  color: var(--grey-text-color) !important;
}

.color-4 {
  color: var(--panel-color) !important;
}

.color-9,
.white-text {
  color: #fff !important;
}

.bg-panel-color {
  background-color: var(--panel-color) !important;
}

.bg-color-main {
  background-color: var(--main-background-color) !important;
}

.bg-color-1 {
  background-color: white !important;
}

.bg-color-2 {
  background-color: white !important;
}

.bg-color-3 {
  background-color: var(--panel-color) !important;
}

.bg-color-4 {
  background-color: white !important;
}

.font-1 {
  font-family: "Open Sans", arial, sans-serif;
}

.link-btn,
.link-btn-round.active {
  background-color: #222;
  color: #fff;
}

.link-btn-round,
.link-btn.inactive {
  background-color: #f2f2f2;
  color: #222;
}

.link {
  color: #fff;
}

.frame {
  /* background-image: url("/resources/images/background-image.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #222;
}

.frame-body-left {
  /*background-color: rgba(255, 255, 255, 0.1);*/
}

.frame-header {
  background-color: rgba(0, 0, 0, 0.1);
}

.frame-footer {
}

.loading-indicator {
  color: #3e7bfa;
  background-color: #fff;
}

.btn-primary {
  background-color: #3e7bfa;
  border-color: #3e7bfa;
  color: #fff;
}

button:hover,
a:hover {
  filter: brightness(120%) !important;
}

.toggle-menu-btn {
  color: white;
}

/* a.active,
.menu-item.active {
  background: #28293d;
  border-left: 5px solid var(--primary-color);
} */

.card {
  /*background-color: #f8f8f8;*/
}

.box {
  /*background-color: #f8f8f8;*/
}

.page ul {
  list-style: none;
}
.page ul li {
}

.page ul li::before {
  /* background-image: url("/cached/iTrazo_Bullet_Red.png"); */

  background-size: contain;
  background-repeat: no-repeat;
}

.site-header ul {
  background-color: rgba(255, 255, 255, 0.95);
}

@media screen and (min-width: 1200px) {
  .site-header ul {
    background-color: transparent;
  }
}

.site-header ul li a:after {
  background: #3e7bfa;
}

.link {
  color: #fff;
}

.link:hover {
  text-decoration: none;
  color: #fff;
}

.logincard {
  background-color: #303248;
  color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.32), 0px 0px 2px rgba(0, 0, 0, 0.04);
}

@media (min-width: 1000px) {
  .logincard {
    background-color: unset !important;
    box-shadow: none !important;
    color: white;
    padding: 30px;
    border-radius: 10px;
  }
}

.my-input {
  background: transparent !important;
  color: #fff !important;
  border: 0.5px solid #989898 !important;
}
.input-customize {
  height: 40px !important;
  font-size: 14px !important;
}

.pd-5 {
  border-left: 5px solid #454545;
}
.accordion__button:before {
  content: unset !important;
}
.accord:nth-child(even) .accordion__button {
  background-color: #333333 !important;
}
.accord:nth-child(odd) .accordion__button {
  background-color: #383838 !important;
}
.accordion {
  border: 0px solid transparent !important;
}
.DateRangePicker_picker {
  z-index: 9 !important;
}
