/* @font-face {
  font-family: "Gotham Book";
  src: url("../resources/fonts/Gotham Book.otf") format("opentype");
}

@font-face {
  font-family: "Gotham Bold";
  src: url("../resources/fonts/Gotham Bold.otf") format("opentype");
}

@font-face {
  font-family: "Gotham Medium";
  src: url("../resources/fonts/Gotham-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Gotham Light";
  src: url("../resources/fonts/Gotham-Light.otf") format("opentype");
}

@font-face {
  font-family: "Gotham Thin";
  src: url("../resources/fonts/Gotham-Thin.otf") format("opentype");
}

@font-face {
  font-family: "Gotham XLight";
  src: url("../resources/fonts/Gotham-XLight.otf") format("opentype");
} */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  /* Font */
  /* --font-bold: "Gotham bold", Tahoma, Geneva, sans-serif;
  --font-normal: "Gotham Book", Tahoma, Geneva, sans-serif;
  --font-thin: "Gotham thin", Tahoma, Geneva, sans-serif;
  --font-light: "Gotham light", Tahoma, Geneva, sans-serif;
  --font-xlight: "Gotham xlight", Tahoma, Geneva, sans-serif; */

  --font-normal: "Inter", sans-serif;

  /* Colors */
  --primary-color: #3e7bfa;
  --itrazo-red-color: #e33622;
  --white-color: #fff;
  --black-color: #000;
  --main-background-color: #28293d;
  /* for page background */
  /* --panel-color: #383838; */
  --panel-color: #2b2d42;
  /* for container background */
  --grey-text-color: #808080;
  --white-text-color: #fff;
  --white-text-color-rgb: 255, 255, 255;
  --side-bar-color: #2e2e45;
  --green-color: #77b900;
  --grey-border-color: #5e5e5e;
  /* --input-border-color: #8f90a6; */
  --input-border-color: #626262;
  --border-radius: 4px;
  --grey-background-color: #b9b9b9;
  --blue-color: #5865f2;
  --light-blue-color: #50a1a5;
  --orange-color: #e5b800;
  --yellow-color: #fae69e;
  --dropdown-bg-color: #303047;
  --dropdown-select-color: #555770;
  --modal-header-color: #32334a;
  --modal-body-color: #28293d;
  /* --modal-footer-color: #2d2e45; */
  --modal-footer-color: #32334a;
  --modal-subBody-color: #2f3148;
  --details-header-color: #32334d;
  --table-card-header-color: #32334a;

  /* Button colors */
  --btn-disabled-color: #555770;
  --btn-red-filled: linear-gradient(180deg, #e33622 51.39%, #ba1200 100%);
  --btn-red-filled-hover: linear-gradient(
    178.58deg,
    #f6b458 -76.87%,
    #c61d0a 98.79%
  );
  --btn-red-filled-font: #fff;
  --btn-red-outlined: #3e7bfa;

  --btn-grey-filled: linear-gradient(180deg, #5e5e5e 0%, #414141 100%);
  --btn-grey-filled-hover: linear-gradient(180deg, #6a6a6a 0%, #262626 100%);
  --btn-grey-filled-font: #fff;

  --btn-yellow-filled: linear-gradient(180deg, #fff1bd 11.81%, #fae69e 62.85%);
  --btn-yellow-filled-hover: linear-gradient(
    180deg,
    #fffbeb -9.08%,
    #fae69e 122.5%
  );
  --btn-yellow-filled-font: #000;
  --btn-yellow-outlined: #fae69e;
  --btn-yellow-outlined-hover-font: #fff;

  /* Table stats */
  /* --table-header-bg-color: var(--main-background-color); */
  --table-header-bg-color: #383a56;
  /* --table-row-bg-color-light: #30324a; */
  --table-row-bg-color-light: #2c2d42;
  --table-header-font-color: #f5f5f5;
  --table-row-light-hover: #595b88; /* Changed from #565770 to primary color */
  --table-row-bg-color-dark: #2c2d42;
  --table-row-dark-hover: #595b88; /* Changed from #565770 to primary color */
  --table-row-border-color: #8f90a680;
  --table-font-color: #c3c3c3; /* Changed from #dedede to #c3c3c3; */
  --table-row-padding: 5px 10px 5px 10px;
  --table-header-padding: 13px 10px 13px 10px;
  --table-row-height: 45px;

  /* Side panel */
  --side-panel-text-color: #c3c3c3;

  /* Adi Steps  */
  --active-step-top-border: #004fc4;
  --active-step-icon-color: #555770;
  --active-step-bg-color: #5b8def;

  /* stats color  */
  --stats-text-color: #000000;
}

html {
  font-size: 10px;
  font-size: 62.5%;
  --antd-wave-shadow-color: var(--primary-color);
}

body {
  font-size: 1.05em;
  padding: 0;
  margin: 0;
  font-family: var(--font-normal);
}

a {
  text-decoration: none;
}

a,
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0.075em;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0.5em 0;
}

input {
  color-scheme: dark;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
}

select {
  font-family: var(--font-normal);
}

option {
  font-family: var(--font-normal);
}

.btn {
  border-radius: 0.25em;
}

.gotham-medium-fourteen {
  font-weight: 400;
  font-size: 14px;
}

.gotham-book-twelve {
  font-family: var(--font-normal);
  font-size: 12px;
}

.font-sixteen {
  font-family: var(--font-normal);
  font-size: 16px;
}

.marg-twenty-four {
  margin-right: 24px;
}

.btn-jumbo {
  min-width: 8em;
  font-size: 1.25em;
  padding: 1em 4em;
}

.stati div {
  width: calc(100% - 3.5em);
  display: block;
  float: right;
  text-align: right;
}

.stati div b {
  font-size: 2.2em;
  width: 100%;
  padding-top: 0px;
  margin-top: -0.2em;
  margin-bottom: -0.2em;
  display: block;
}

.stati div span {
  font-size: 1em;
  width: 100%;
  color: rgb(0, 0, 0, 0.8);
  display: block;
}

.sidebartitles {
  text-transform: capitalize;
}

.logo-size {
  width: 95%;
  height: 100%;
}
.logo-for-mobile-size {
  width: 121.69px;
  height: 50.6px;
  margin-bottom: 10px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-faint {
  opacity: 0.7;
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}

a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

.gmnoprint div {
  /* background:none !important; */
  border-radius: 20px !important;
  /* background-color: whit !important; */
}

.gm-control-active img {
  src: url("../statics/images/ic-plus.svg") !important;
}

.gm-fullscreen-control {
  display: none !important;
}

#tableChart1 {
  height: 800px;
  background: #333;
  border-radius: 5px;
  padding: 10px;
}

.anychart-credits {
  display: none;
}

#tableChart2 {
  height: 800px;
  background: #333;
  border-radius: 5px;
  padding: 10px;
}

#tableChart3 {
  /* height: 200px; */
  background: #333;
  border-radius: 5px;
  padding: 10px;
}

#tableChart4 {
  /* height: 300px; */
  background: #333;
  border-radius: 5px;
  padding: 10px;
}

#tableChart5 {
  height: 200px;
  background: #333;
  border-radius: 5px;
  padding: 10px;
}

/* .ant-form-item-explain-error {
  font-size: 12px;
} */

.gm-style-mtc > button {
  background-color: #545454 !important;
  color: white !important;
}

.gm-style-mtc > button > ul {
  display: none !important;
}

/* ul {
  display: none !important;
} */

/* photo taker style */
#video {
  width: 100%;
  /* width: 100vw; */
  margin-bottom: 24px;
}

#canvas {
  width: 100%;
  margin-bottom: 24px;
}

/* .anticon anticon-paper-clip.camera-icon-span {
  margin-left: 5px;
} */

.map-scroll {
  margin-top: 5px;
  overflow-y: scroll;
}

.report-style-class {
  height: 100vh;
  font-weight: bold;
  padding-bottom: 20px;
}

.hider {
  z-index: 1000;
  height: 20px;
  margin-top: 70px;
  transform: translateY(-80px);
  background-color: #222;
}

.table-dark {
  background-color: #383838 !important;
}

th {
  font-weight: bold;
  /* font-family: var(--font-normal); */
  padding-bottom: 20px;
}

.adi-modal-content {
  background-color: #383838 !important;
}

.modal-header,
.modal-header > button > span {
  border-bottom: 0px;
  color: white;
}

.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  display: none;
  width: 100px;
  background-color: #191919;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: -710%;
  left: 130%;
  margin-left: -80px;
}

/* Popup arrow */
.popup .popuptext::after {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -10px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #191919;
}

/* Toggle this class - hide and show the popup */
.popup .show {
  display: block;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.detail__popup {
  display: block;
  visibility: visible;
  box-sizing: border-box;
  position: absolute;
  left: 30%;
  top: 43px;
  height: auto;
  width: 40%;
  margin: auto;
  z-index: 1;
  border-radius: 5px;
  border: 0px solid rgb(210, 210, 210);
  padding: 1rem 1rem 2rem;
  background: #191919;
}

/* Modal css for view user */
.adi-modal {
  /* display: none;  */
  display: block;
  position: absolute;
  /* Stay in place */
  z-index: 3;
  /* Sit on top */
  /* padding-top: 100px;  */
  /* left: 0; */
  padding: 10px 0;
  /* left: 5000px; */
  top: 0;
  right: 5000px;
  /* width: 100%; Full width  */

  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  transition: left 300ms;
}

.adi-side-modal {
  /* right: 5000px; */
  width: 100%;
  /* Full width */
}

.adi-side-modal {
  /* right: 5000px; */
  width: 100%;
  /* Full width */
}

.confirm-modal {
  display: none;
  /* display: block; */
  position: absolute;
  /* Stay in place */
  z-index: 3;
  /* Sit on top */
  /* padding-top: 100px;  */
  left: 0;
  padding: 10px 0;
  /* left: 5000px; */
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  /* transition: left 300ms */
}

.confirm-modal-content {
  background-color: #fefefe;
  padding: 10px;
  border-radius: 5px;
  border: 0px;
  width: 35%;
  overflow: auto;
  margin: 0 auto;
  margin-top: 20%;
  transform: translateY(-50%);
  background-color: #191919 !important;
}

.detail_modal {
  display: none;
  position: absolute;
  /* Stay in place */
  z-index: 3;
  /* Sit on top */
  padding-top: 100px;
  left: 0;
  padding: 10px 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  transition: left 300ms;
}

/* Modal Content */
.adi-modal-content {
  float: right;
  background-color: #fefefe;
  /* margin: auto; */
  padding: 10px;
  border-radius: 5px;
  border: 0px;
  /* width: 50%; */
  width: 85%;
  /* height: 80vh; */
  height: 100%;
  overflow: auto;
}

.adi-full-modal-content {
  width: 85%;
}

/* for half modal, content will take the whole width */
.adi-half-modal-content {
  height: 100%;
}

.detail_modal_content {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 43px;
  height: auto;
  width: 100%;
  margin: auto;
  z-index: 1;
  border-radius: 5px;
  border: 0px solid rgb(210, 210, 210);
  padding: 1rem 1rem 2rem;
  background: #191919;
}

.recallModal {
  display: block;
  position: absolute;
  /* Stay in place */
  z-index: 2;
  /* Sit on top */
  /* padding-top: 100px;  */
  padding: 10px 0;
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  transition: left 300ms;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 24px;
  cursor: pointer;
  margin-top: -11px;
}

.disabled-panel {
  pointer-events: none;
}

.disabledBtn {
  background-color: gray;
  color: rgb(249, 249, 249, 0.8);
  cursor: auto;
}

.disabledBtn:hover {
  background-color: gray;
}

.MuiTab-wrapper {
  font-family: var(--font-normal);
  color: white;
  font-size: 14px;
}

.accordion__button {
  background-color: #383838;
}

.accordion__button:hover {
  background-color: #383838;
}

.accordion__button::before {
  display: none !important;
}

.hideTableBody {
  max-height: 0 !important;
  opacity: 0 !important;
  position: absolute !important;
}

.CalendarMonth_caption,
.CalendarMonth_caption_1 {
  color: white;
}

.rightMapPanel {
  display: block;
}

.rightViewPanel {
  display: block;
}

#trackTableBody tr:last-child > td {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

#drop-area.highlight {
  border-color: red;
}

.PhoneInputInput {
  color: white;
  border: none;
  background: transparent;
}

.PhoneInputInput--focus {
  border: 1px solid red;
}

.react-datepicker {
  font-family: var(--font-normal);
}

.rc-time-picker-panel-inner {
  box-shadow: none;
}

.react-datepicker-wrapper,
.rc-time-picker {
  width: 98%;
  /* background: pink */
}

.react-datepicker__input-container > input,
.react-datepicker-ignore-onclickoutside,
.rc-time-picker-input {
  width: 100%;
  background: #383838;
  color: white;
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
  height: 26px;
  padding-left: 5px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 12px;
}

.rc-time-picker-panel-select ul {
  display: block !important;
}

/* remove the triangle */
.react-datepicker__triangle {
  display: none;
}

.react-datepicker-popper {
  padding: 5px !important;
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  display: none;
}

.MuiTabScrollButton-root {
  width: 20px !important;
  color: white !important;
}

.chain-detail-card {
  display: none;
}

.disable-whole-panel {
  pointer-events: none;
}

.removeVertical .anticon {
  vertical-align: 0px !important;
}

/* Header */
.tablecustomise .ant-table-thead .ant-table-cell {
  background-color: var(--table-header-bg-color) !important;
  padding: var(--table-header-padding);
  color: var(--table-header-font-color) !important;
}

.tablecustomise .ant-table-thead > tr > th {
  /* border: 1px solid var(--table-row-border-color); */
  /* header border */
  border-top: 1px solid var(--table-row-border-color);
  /* header border */
  border-bottom: 2px solid var(--table-row-border-color);
  /* header border */
  transition: background 0.3s !important;
  font-size: 12px !important;
}

/* .tablecustomise .ant-table-container table > thead > tr > th:nth-last-child(2) {
  border-right: unset !important;
} */

/* Row */
.tablecustomise .ant-table-tbody > tr > td {
  border-bottom: 1px solid var(--table-row-border-color);
  /* row border color */
  transition: background 0.3s;
}

/* The table borders can be added through props in AdiTable component */

.tablecustomise .table-row-light td {
  background-color: var(--table-row-bg-color-light) !important;
  opacity: 1;
  color: var(--table-font-color) !important;
  height: var(--table-row-height) !important;
  padding: var(--table-row-padding);
}

.tablecustomise .table-row-dark td {
  background-color: var(--table-row-bg-color-dark) !important;
  opacity: 1;
  color: var(--table-font-color) !important;
  height: var(--table-row-height) !important;
  padding: var(--table-row-padding);
}

.tablecustomise .table-row-light:hover > td {
  background-color: var(--table-row-light-hover) !important;
}

.tablecustomise .table-row-dark:hover > td {
  background-color: var(--table-row-dark-hover) !important;
}

.tablecustomise .ant-table-content table {
  opacity: 1;
  color: var(--table-font-color) !important;
}

.tablecustomise .ant-table-thead .ant-table-cell {
  opacity: 1;
  color: var(--table-header-font-color) !important;
}

.tablecustomise .table-row-light.table-row-pointer {
  cursor: pointer;
}

.tablecustomise .table-row-dark.table-row-pointer {
  cursor: pointer;
}

.tablecustomise .ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: var(--primary-color) !important;
}

.tablecustomise tr.ant-table-expanded-row > td {
  background-color: unset !important;
}

.tablecustomise .ant-table-row-expand-icon {
  background: var(--grey-text-color) !important;
  border: 1px solid grey !important;
  color: white !important;
}

.tablecustomise .ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: var(--table-row-dark-hover) !important;
}

.tablecustomise .ant-table-tbody > tr > td.ant-table-cell > p {
  margin-bottom: 0;
}

/* small padding table used in modal */
.small-padding-table.tablecustomise .ant-table-content > table thead > tr > th {
  padding: 0.5rem;
}

/* allocation table give only left padding to expanadable row */
.allocationTableCss tr.ant-table-expanded-row > td {
  padding: 0px 0px 0px 50px !important;
}

.editable-phone-input .PhoneInputInput {
  background-color: transparent !important;
  color: white !important;
}

.editable-phone-input .PhoneInputInput:focus {
  background-color: white !important;
  color: black !important;
}

input:disabled,
textarea:disabled,
select:disabled {
  cursor: not-allowed;
}

.largeTitle {
  font-size: 2rem;
  margin-top: -7px;
}

.chart-container {
  width: 100%;
}

.highcharts-credits {
  display: none;
}

.highcharts-background {
  fill: transparent !important;
}

.highcharts-axis-title {
  fill: white;
}

.highcharts-yaxis-labels text {
  fill: white !important;
}

.highcharts-xaxis-labels text {
  fill: white !important;
}

.highcharts-legend-item text {
  fill: white !important;
}

.highcharts-title {
  fill: #fff !important;
}

.cCKsmM {
  overflow-y: auto;
}

.pad-letter-left {
  padding-left: 0.4rem !important;
}

/* Multiselect of antd design */
.multi_Select {
  width: 100%;
}

.multi_Select .table-col-select {
  width: 100%;
}

input[type="text"],
input[type="password"],
input[type="number"],
textarea {
  padding-left: 12px !important;
  font-size: 12px !important;
  font-family: var(--font-normal) !important;
}

.orderCollapse .ant-collapse {
  border: 1px solid #7e7e7e !important;
}

.orderCollapse .ant-collapse > .ant-collapse-item {
  border: 1px solid #7e7e7e !important;
  border-radius: 4px 4px 4px 4px !important;
  border-bottom: 1px solid #7e7e7e;
}

.alert-collapse {
  border: 1px solid #7e7e7e !important;
}

.alert-collapse .ant-collapse-item {
  border-bottom: 1px solid #7e7e7e !important;
}

.accordianClass .ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 10px !important;
}

#modal-btn-width-regular {
  width: 100px !important;
}

#modal-btn-width-small {
  width: 80px !important;
}

.react-datepicker,
.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker {
  font-size: calc(1.3rem * 1.2) !important;
}

.react-datepicker__month,
.react-datepicker__day-names {
  padding-left: 10%;
  padding-right: 10%;
}

.react-datepicker__month {
  padding-bottom: 10px;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex !important;
  justify-content: space-evenly !important;
}

.react-datepicker__current-month {
  font-size: calc(1.5rem * 1.2) !important;
}

.react-datepicker__header {
  padding-top: calc(6px * 1.2) !important;
}

.react-datepicker__navigation {
  top: calc(13px * 1.2) !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: calc(0.7rem * 1.2) !important;
}

.react-datepicker__month-container,
.react-datepicker__day,
.react-datepicker-year-header,
.react-datepicker__day-name {
  background-color: #585858 !important;
  color: white !important;
}

.react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #585858;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  background-color: #585858 !important;
  color: white !important;
}

.react-datepicker__current-month,
.react-datepicker__current-month--hasMonthDropdown {
  color: white !important;
}

.react-datepicker__month-dropdown,
.react-datepicker__month-option {
  background-color: #585858 !important;
  color: white !important;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: rgb(204, 204, 204, 0.5) !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: rgb(204, 204, 204, 0.5) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  background-color: var(--primary-color) !important;
}

.ant-modal-body #alert-location-map {
  color: #000;
}

/******** media queries ********/

@media only screen and (min-width: 440px) {
  .adi-full-modal-content {
    width: 75%;
  }
}

@media only screen and (min-width: 700px) {
  .adi-full-modal-content,
  .issue-detail-modal-content {
    width: 50%;
  }
}

/* ipad width */
@media only screen and (min-width: 744px) {
  .detail-modal-content {
    width: 40%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: 1150px) {
  .rightPanel {
    width: 40%;
  }

  .rightMapPanel {
    display: block;
  }

  .rightViewPanel {
    display: none;
  }

  .adi-full-modal-content,
  .issue-detail-modal-content {
    width: 35%;
  }

  .chain-accordion-panel {
    display: none;
  }

  .chain-detail-card {
    display: block;
  }

  .largeTitle {
    font-size: 2.5rem;
  }
}

@media only screen and (min-width: 1320px) {
  .largeTitle {
    font-size: 3rem;
  }
}

@media only screen and (min-width: 1500px) {
  .adi-full-modal-content {
    width: 40%;
  }

  .issue-detail-modal-content {
    width: 35%;
  }
}

@media (max-width: 415px) {
  .temp_divs {
    float: left;
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }

  .chart-div {
    width: 99.2%;
  }
}

@media only screen and (min-width: 300px) {
  .temp_divs {
    float: left;
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }

  .chart-div {
    width: 99.2%;
  }
}

@media only screen and (min-width: 1300px) {
  .temp_divs {
    width: 30%;
    float: right;
    margin-top: 0px;
  }

  .chart-div {
    width: 69.2%;
  }

  .chart-div div:nth-child(1) {
    width: 100% !important;
    display: block;
  }

  .highcharts-background {
    width: 128%;
    x: -86px;
  }

  .highcharts-root {
    width: 100%;
    /* height: 350px; */
  }
}

@media only screen and (min-width: 1340px) {
  .temp_divs {
    width: 30%;
    float: right;
    margin-top: 0px;
  }

  .chart-div {
    width: 69.2%;
  }

  .highcharts-background {
    width: 124%;
  }

  .highcharts-root {
    width: 100%;
    /* height: 339px; */
  }
}

@media only screen and (min-width: 2000px) {
  .temp_divs {
    width: 30%;
    float: right;
  }

  .highcharts-background {
    width: 120%;
    x: -86px;
  }

  .highcharts-root {
    width: 100%;
    height: 300px;
  }
}

/* OSM CSS START */

#product_info .tablecustomise .ant-table-thead > tr > th,
#product_info .tablecustomise .ant-table-tbody > tr > td {
  border: 0px solid transparent !important;
  padding: 7.5px 15px;
  width: 200px;
}

#product_info .tablecustomise .ant-table-tbody > tr > td {
  padding: 11.5px 15px;
}

#product_info .ant-form-item {
  margin-bottom: 0;
}

#product_info .orderTable input[type="number"] {
  width: 100% !important;
  /* border: 1px solid rgba(94, 94, 94, 1) !important; */
}

#product_info .orderTable td {
  background-color: transparent !important;
}

#product_info .orderTable {
  border: 1px solid #444444;
}
/* 
#product_info
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid rgba(94, 94, 94, 1) !important;
} */

.orderTable th {
  width: 25%;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  padding: 9px 17px 9px 24px;
  font-weight: 500;
  background-color: var(--table-header-bg-color);
}

.orderTable td {
  padding: 5px 24px;
  font-family: var(--font-normal);
  font-size: 12px;
  color: #fff;
  font-weight: 500;
}

.orderTable .ant-form-item-control-input {
  margin: 5px 0 10px 0;
}

.purchaseEditableTable th {
  width: 18% !important;
}

.padLeftCol {
  padding-left: 24px;
}

#packaging_info .ant-form-item-control-input-content label {
  font-size: 14px;
}

#packaging_info .labelDesign {
  font-size: 14px;
  margin-bottom: 6px;
  color: #fff;
  font-family: var(--font-normal);
}

#delivery_info_som
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  margin-top: 0 !important;
}

#delivery_info_som .ant-collapse,
#delivery_info_som .ant-collapse > .ant-collapse-item {
  background-color: transparent !important;
  border: 0px solid transparent !important;
  margin-bottom: 20px;
}

#delivery_info_som .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 16px;
  font-family: var(--font-normal);
  padding: 20px 24px;
  margin-bottom: 0px;
}

#delivery_info_som .ant-collapse-content > .ant-collapse-content-box {
  width: 92%;
  margin: 0 24px;
  padding: 0 !important;
}

#delivery_info_som .orderTable {
  width: 100%;
}

#delivery_info_som .orderTable th {
  width: unset !important;
}

#product_info .ant-form-item-explain-error {
  padding-left: 24px;
  font-size: 12px;
}

#product_info .orderTable .ant-form-item-explain-error {
  padding-left: 0;
  font-size: 12px;
}

/* .crm-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  top: 0;
}

.crm-steps .ant-steps-item-icon .ant-steps-icon {
  top: -1px;
} */
.inputs {
  align-self: flex-start;
}

#customer_info .ant-form-item-explain-error {
  padding-left: 0 !important;
}

/******  CRM Steps start   ******/
#crm-steps .ant-steps-item-container {
  /* background: #2f2f2f; */
  background: var(--side-bar-color);
  padding: 13px;
  height: 48px;
}

#crm-steps
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item {
  padding-left: 0;
  border-right: 1.5px solid rgba(185, 185, 185, 0.45);
  height: 48px;
}

#crm-steps
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item:last-child {
  border-right: 0px solid transparent;
}

#crm-steps .ant-steps-item-title {
  line-height: 22px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
}

#crm-steps .ant-steps-item-title::after {
  background: unset;
}

#crm-steps
  .ant-steps-item-finish
  > #crm-steps---
  .ant-steps-item-container
  > #crm-steps
  .ant-steps-item-content
  > #crm-steps
  .ant-steps-item-title::after {
  background: unset;
}

#crm-steps .ant-steps-item-content {
  width: 90%;
}

#crm-steps .ant-steps-item-icon {
  width: 25px;
  height: 25px;
  margin-top: -2px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.4);
}

#crm-steps .ant-steps-item-finish .anticon svg {
  width: 20px;
  height: 18px;
  color: #77b900;
  top: 2px;
}

#crm-steps .anticon-down svg,
#crm-steps .ant-select-clear svg {
  color: rgba(152, 152, 152, 1);
}

#crm-steps .ant-select-arrow {
  right: 16px;
  width: 18px;
  height: 12px;
  margin-top: -8px;
}

#crm-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  top: -5px;
}

#crm-steps .ant-steps-item-finish .ant-steps-item-icon {
  background: transparent;
  border: 2px solid #77b900;
}

#crm-steps .ant-steps-item:last-child {
  flex: 1;
}

#crm-steps
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item-description {
  max-width: 350px;
  color: #fff;
  font-family: var(--font-normal);
  font-weight: 400;
  font-size: 12px;
}

#crm-steps .ant-steps-item-icon .ant-steps-icon {
  font-size: 16px;
  top: -5.5px;
  color: rgba(255, 255, 255, 0.4);
}

#crm-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  border: 2px solid #ffffff;
}

#crm-steps .ant-steps-item-active {
  border-top: 1.5px solid #3e7bfa;
  height: 80px;
}

#crm-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon {
  color: #fff;
}

#crm-steps .ant-steps-item-subtitle {
  font-size: 12px;
  padding: 4px 10px 2px;
}

/******  CRM steps end   ******/

/* Reject Order CSS */

.rejectOrder label {
  font-size: 14px;
  padding-bottom: 10px;
}

.rejectOrder .cs_datePicket {
  height: 40px;
}

.rejectOrder .filters_bar {
  background: #383838;
  padding: 0 35px;
}

.rejectOrder .filters_bar label {
  padding-top: 12px;
}

.rejectOrder .borderLeft {
  border-left: 1px solid #000000;
  padding-left: 22px !important;
  margin-left: 10px !important;
}

.rejectOrder .ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #fff !important;
}

.rejectOrder table label {
  padding-bottom: 0;
}

.rejectOrder table {
  border: 1px solid #a1a1a1;
  border-radius: 0;
}

.rejectOrder .filters_bar .ant-row.ant-form-item {
  margin: 0;
}

.rejectOrder .itemCol p {
  font-size: 10px;
  margin: 0;
  color: #dedede;
}

.rejectOrder .itemCol h6 {
  font-weight: 500;
  font-size: 14px;
  color: #dedede;
  margin: 0;
}

.reject-order-modal .ant-modal-body {
  background: var(--modal-body-color);
}

.PhoneInputCountrySelectArrow {
  color: #fff;
}

.fixedHeightTextarea {
  height: 10vh !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f90a6 !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8f90a6;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8f90a6;
}

.actionItemSelectReject .ant-form-item {
  margin-bottom: 0px !important;
}

.respBord {
  border-top: 1px solid #4f4f4f;
  width: 77%;
  position: absolute;
  right: 5px;
  top: 11px;
}

.respBord1 {
  width: 83%;
}

.respBord2 {
  width: 79%;
}

/* ant dropdown with menu */
.dropdownMenu,
.ant-dropdown-placement-bottomLeft .ant-dropdown-menu {
  /* text-align: center !important; */
  background: var(--dropdown-bg-color) !important;
  /* border: 1px solid rgba(128, 128, 128, 0.4); */
  border-radius: 2px !important;
  padding: 0px !important;
}

.dropdownMenu .ant-dropdown-menu-item {
  transition: unset !important;
  color: #fff !important;
  /* border-bottom: 1px solid rgba(128, 128, 128, 0.4); */
}

.dropdownMenu .ant-dropdown-menu-title-content {
  text-align: left !important;
}

.dropdownMenu .ant-dropdown-menu-item:hover {
  background: var(--dropdown-select-color) !important;
  /* border: 1px solid rgba(250, 230, 158, 0.5); */
}

@media (max-width: 1765px) {
  .respBord {
    width: 75%;
  }

  .respBord1 {
    width: 81%;
  }

  .respBord2 {
    width: 77%;
  }
}

@media (max-width: 1645px) {
  .respBord {
    width: 73%;
  }

  .respBord1 {
    width: 79%;
  }

  .respBord2 {
    width: 75%;
  }
}

@media (max-width: 1480px) {
  .respBord {
    width: 70%;
  }

  .respBord1 {
    width: 76%;
  }

  .respBord2 {
    width: 72%;
  }
}

@media (max-width: 1400px) {
  .respBord {
    width: 68%;
  }

  .respBord1 {
    width: 74%;
  }

  .respBord2 {
    width: 70%;
  }
}

@media (max-width: 1300px) {
  .respBord {
    width: 66%;
  }

  .respBord1 {
    width: 72%;
  }

  .respBord2 {
    width: 68%;
  }
}

@media screen {
  .print-screen {
    overflow: hidden;
    /* // display: none; */
    height: 0;
  }
}

.assignBtn {
  background: none !important;
  border: 1px solid #3e7bfa !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
  height: 40px !important;
  font-weight: 700 !important;
  font-size: 14px;
}

.editDropBtn {
  width: 100px;
  height: 30px;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.32);
  border-radius: 4px;
}
.overview-details .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-top: 20px;
  padding-bottom: 20px;
}

.EditDrop {
  border-radius: 4px;
  overflow: hidden;
}
.EditDrop li {
  width: 100px;
  text-align: center;
  font-weight: 500;
}
.mobileChangeToColumn {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  align-items: center;
  /* gap: 25px !important; */
}
.rightContainer {
  background: unset !important;
  height: 100vh;
  padding-top: 10rem;
  width: 100vw;
}
.showItrazoImage {
  display: none;
}
.showLogoMobile {
  display: block;
}
.remove-left-margin {
  margin-left: auto !important;
}
/* // for login page */
@media (min-width: 1000px) {
  .mobileChangeToColumn {
    flex-direction: row;
  }
  .rightContainer {
    background: #2d2f45 !important;
    height: 100vh;
    width: unset !important;
    padding-top: 20rem;
  }
  .showItrazoImage {
    display: block;
  }
  .showLogoMobile {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .remove-left-margin {
    margin-left: -50px !important;
  }
}

@media (min-width: 768px) {
  .mobileChangeToColumn .ant-col-md-15 .ant-col-md-9 {
    max-width: 100% !important;
  }
}
.checkModules {
  background: #2d2e45 !important;
  width: 100% !important;
  padding: 8px;
  margin-top: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}
.checkModules .ant-checkbox + span {
  padding-right: 8px;
  padding-left: 16px;
}
#addUser .ant-radio-wrapper span {
  color: #e4e4eb !important;
}
.dragdrop {
  margin-bottom: 40px;
}
.dragdrop .ant-upload-list {
  position: absolute;
  top: 0;
  margin: 21px;
}
.dragdrop .ant-upload.ant-upload-drag .ant-upload {
  padding: 10px 0 !important;
}
.dragdrop .ant-upload.ant-upload-drag {
  border-radius: 4px !important;
  background: #30324a !important;
}
.dragdrop .ant-upload-text,
.dragdrop .ant-upload-drag-icon {
  color: #8f90a6 !important;
}
.dragdrop .ant-upload-list-picture-card-container {
  margin: 30px 0 !important;
}
.radioGroup .ant-radio-inner {
  background-color: transparent !important;
  border-color: #8f90a6 !important;
}
.radioGroup .ant-radio-inner::after,
.ant-radio-disabled .ant-radio-inner::after {
  background-color: #00d100 !important;
}
.radioGroup .ant-radio-checked .ant-radio-inner {
  border-color: #00d100 !important;
  border: 1px solid #00d100 !important;
}
.radioGroup .ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 0px #e6f7ff;
}
.ant-picker-suffix .anticon,
.ant-select-arrow,
.ant-select-selection-placeholder,
.ant-picker-clear .anticon {
  color: #8f90a6 !important;
}
@media (max-height: 870px) {
  .height-65vh {
    height: 65vh !important;
  }
}
@media (max-height: 800px) {
  .height-65vh {
    height: 60vh !important;
  }
}
@media (max-height: 720px) {
  .height-65vh {
    height: 58vh !important;
  }
}
@media (max-height: 680px) {
  .height-65vh {
    height: 50vh !important;
  }
}
.productIDKey input {
  text-transform: uppercase;
}

.map-marker-green {
  background: none;
  border: none;
  /* position: relative;
  left: -20px;
  top: -20px; */
}
.map-marker-green:hover {
  * {
    display: block;
  }
}
.map-marker-green:active {
  * {
    display: block !important;
  }
}

.map-marker-green svg {
  color: green;
  width: 25px;
}
.cluster-marker {
  /* color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center; */

  /* width: 40px;
  height: 40px; */

  /* position: relative; */
  /* left: -20px; */
  /* top: -20px; */
  padding: 10px;
  border-radius: 50%;
  background-color: white;
  text-align: center;

  color: #333;
  font-size: 1.16rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid red;
}

.help_support,
.ant-dropdown-menu {
  background: var(--dropdown-bg-color) !important;
  border: 1px solid rgba(128, 128, 128, 0.4);
  border-radius: 2px !important;
}

/* .help_support,
.ant-dropdown-menu-item:hover {
  background-color: #303047 !important;
} */
