/* Overwrite antd css styling GLOBALLY  */

.ant-form-item-explain-error {
  font-size: 12px;
}

.anticon anticon-paper-clip.camera-icon-span {
  margin-left: 5px;
}

/* date picker style, overwrite the react-datepicker style */
.ant-picker.ant-picker-range .ant-picker-range-separator .ant-picker-separator {
  color: rgba(255, 255, 255, 0.25);
}

.ant-picker-input > input {
  font-family: var(--font-normal);
  font-size: 12px;
  color: white;
}

.ant-picker {
  padding: 3.5px 11px 3.5px;
  border-color: var(--input-border-color);
  border-radius: 5px;
  background-color: inherit;
  width: 100%;
}

.ant-picker-panel-container ul {
  display: block !important;
}

.ant-picker-clear {
  background-color: #383838;
}

.ant-picker-suffix .anticon {
  color: gray;
}

.ant-picker-clear .anticon {
  color: gray;
}

.ant-picker-clear .anticon:hover {
  color: gray;
}

.anticon {
  vertical-align: 2px !important;
  /* vertical-align: 4px !important; */
}
/* table dropdown style */
.ant-dropdown.adi-table-dropdown > div.ant-dropdown-arrow {
  border-color: var(--dropdown-bg-color) transparent transparent
    var(--dropdown-bg-color);
}

.ant-dropdown.adi-table-dropdown > ul {
  background-color: var(--dropdown-bg-color);
}

.ant-dropdown.adi-table-dropdown > ul > li {
  margin-top: 0;
  margin-bottom: 0;
}

.ant-dropdown.adi-table-dropdown > ul > li:hover {
  background-color: var(--dropdown-select-color);
}

.ant-dropdown.adi-table-dropdown > ul > li > span {
  color: white;
}

/* Global Table Css */
.ant-table {
  background: transparent !important;
}

/* No need borders */

/* .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 4px;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 4px;
}

.ant-table-container table > tbody > tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}

.ant-table-container table > tbody > tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
} */

/* Filter icon hover */
.ant-table-filter-trigger:hover,
.ant-table-column-sorters:hover .ant-table-column-sorter {
  /* color: #a6a6a6; */
  color: var(--primary-color) !important;
  background-color: unset !important;
}

.ant-table-filter-dropdown .ant-empty {
  background-color: var(--dropdown-bg-color);
}

.ant-empty-description {
  color: white;
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}

/* antd input search style */
.ant-input-group-wrapper .ant-input-group > input.ant-input {
  border: 1px solid gray !important;
  background-color: #383838;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: white;
}

.ant-input-group-wrapper .ant-input-group > span.ant-input-group-addon {
  background-color: #383838;
}

.ant-input-group-wrapper
  .ant-input-group
  > span.ant-input-group-addon
  > button.ant-input-search-button {
  border: 1px solid gray !important;
  background-color: #383838;
  border-radius: 5px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.ant-input-group-wrapper
  .ant-input-group
  .ant-input-group-addon
  > button.ant-input-search-button
  > span.anticon-search {
  color: white;
}

.ant-select-arrow {
  color: rgba(255, 255, 255, 0.25);
}

.ant-uploaded-image-text {
  color: white;
}

/* antd number input style */
.ant-input-number {
  border: 1px solid gray !important;
  background-color: #383838;
  border-radius: 5px !important;
  color: white;
}

.ant-input-number-input-wrap > input.ant-input-number-input {
  /* border: 1px solid gray !important;
  background-color: #383838;
  border-radius: 5px !important; */
}

/* antd dropdown input item */
.ant-select-dropdown > div .ant-space-item > input,
.ant-select-dropdown > div .ant-space-item > button {
  border: 1px solid gray !important;
  background-color: var(--dropdown-bg-color);
  border-radius: 5px !important;
  color: rgba(255, 255, 255, 0.75);
}

.ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  > button.ant-btn {
  width: auto !important;
  border-radius: 4px !important;
}

.ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  > button.ant-btn
  span.anticon.anticon-plus
  svg {
  color: white !important;
}

/* Don't do this, will override other icons */
/* .anticon svg {
  color: white;
} */

.ant-message-notice-content {
  border-radius: 4px;
}

.ant-message .anticon {
  top: 3px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit !important;
  border: 1px solid var(--input-border-color) !important;
  color: white;
  border-radius: 5px;
  box-shadow: unset !important;
}

.ant-picker.ant-picker-disabled {
  background: inherit;
  border-color: var(--input-border-color);
}

.ant-picker-input > input[disabled] {
  color: white;
}

/* antd single selector first empty value style  */
span.ant-select-selection-item[title~="Select"] {
  /* opacity: 0.6; */
  color: #b9b9b9;
}

.ant-select-item-option {
  background-color: #2f3047;
  color: white;
}

.ant-select-item-option:hover {
  color: black;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #555770 !important;
  color: white;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #555770 !important;
  color: var(--white-text-color) !important;
}

.ant-picker-panel-container {
  /* background-color: #383838; */
  background-color: var(--dropdown-bg-color);
  /* color: white; */
}

/* when disabled the selector keep the original color */
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: white;
  background: inherit;
}

.ant-picker-header {
  color: white;
}

.ant-picker-header button {
  color: white;
}

.ant-picker-header > button:hover {
  color: #40a9ff;
}

.ant-picker-content th {
  color: white;
}

.ant-picker-cell {
  color: white;
}

.ant-picker-cell-disabled {
  color: rgba(255, 255, 255, 0.25);
}

.ant-picker-cell:hover {
  color: black;
}

/* Start
    colors for selected dates in the antd datePicker */

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  /* background: #d92e1b; */
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before {
  /* background: #ff8e69; */
  background: #e6f7ff50;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before {
  background: #cbe6ff90;
}

/* End */

.ant-picker-footer {
  color: white;
}

.ant-picker-today-btn {
  color: white;
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner {
  color: white;
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner:hover {
  color: black;
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: white;
  color: black;
}

.ant-picker-now-btn {
  color: white;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  /* line-height: 24px !important;
  height: 2.625rem !important; */
  font-size: 1.125rem;
  height: 31px;
  padding: 0 12px !important;
}

.ant-select-multiple .ant-select-selection-item {
  color: var(--white-text-color) !important;
  background-color: #565770 !important;
  border: none !important;
  /* background: transparent !important; */
}

.ant-select-clear {
  background-color: transparent !important;
}

.ant-select-dropdown {
  background-color: var(--dropdown-bg-color) !important;
}

.ant-select-selector {
  /* font-size: 14px !important; */
  font-size: 12px !important;
}

.ant-collapse {
  background-color: #32334d !important;
  border-radius: var(--border-radius) !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: var(--white-text-color) !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  color: var(--white-text-color) !important;
  background-color: #32334d !important;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  /* border: 1px solid var(--grey-border-color) !important; */
  /* border: 1px solid var(--grey-border-color); */
}

.ant-space-vertical {
  width: 100%;
}

.ant-collapse-content {
  background-color: unset !important;
  border-top: unset !important;
}

/* .ant-collapse > .ant-collapse-item {
  border-bottom: unset !important;
} */
.ant-collapse-content > .ant-collapse-content-box {
  padding: 20px 50px;
}

.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 4px !important;
}

.ant-collapse > .ant-collapse-item-active:last-child > .ant-collapse-header {
  border-radius: 4px 4px 0px 0px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-top: 9px !important;
}

/* confirm modal  */
.ant-modal-root > .ant-modal-mask {
  background-color: #d9d9d9 !important;
  opacity: 0.4 !important;
}

.ant-modal-content {
  border-radius: 10px !important;
  background-color: var(--panel-color) !important;
  border: 1px solid #707070 !important;
  opacity: 1 !important;
}

.ant-modal-confirm .ant-modal-body {
  border-top: 2px solid var(--primary-color);
  border-radius: 8px;
}

.ant-modal-confirm-body .ant-modal-confirm-title,
.ant-modal-confirm-content {
  color: var(--white-color) !important;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn {
  background-color: transparent !important;
  border-color: var(--white-text-color) !important;
  border-radius: var(--border-radius) !important;
  color: var(--white-text-color) !important;
  width: 100px !important;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-primary {
  background-color: var(--primary-color) !important;
  border-radius: var(--border-radius) !important;
  color: var(--white-text-color) !important;
  border-color: var(--primary-color) !important;
  width: 100px !important;
}

.ant-popover-content {
  border-radius: var(--border-radius);
}

.ant-popover-buttons .ant-btn-primary {
  background-color: var(--primary-color);
  border-color: transparent;
}

.ant-popover-buttons .ant-btn:hover {
  color: black;
  /* background-color: transparent !important; */
  border-color: transparent;
}

.ant-popover-buttons .ant-btn-primary:hover {
  color: white;
  border-color: transparent;
}

.ant-input-status-error,
.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  border-color: var(--itrazo-red-color) !important;
  background-color: transparent !important;
}

.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
  background-color: transparent !important;
}

.ant-form-item-has-error
  :not(.ant-input-number-affix-wrapper-disabled):not(
    .ant-input-number-affix-wrapper-borderless
  ).ant-input-number-affix-wrapper {
  border-color: var(--itrazo-red-color) !important;
}

/* .ant-radio-group > * {
  color: white;
} */
.ant-radio-wrapper span {
  color: var(--white-color);
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.ant-radio-inner::after,
.ant-radio-disabled .ant-radio-inner::after {
  background-color: var(--primary-color);
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--primary-color);
}

.ant-radio-wrapper {
  margin-right: 10px;
}

.ant-select-item-option-content {
  font-size: 1.2rem;
}

/* Replace range picker two calendars with one calendar */
.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}
.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}
.ant-picker-panels > *:last-child {
  display: none;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}
/* End - Replace range picker calendars */

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: transparent;
}

.ant-upload-list {
  color: var(--white-text-color);
}

.ant-upload-list-item-name {
  color: var(--white-text-color);
}

.ant-upload-list-item-name:hover {
  color: var(--white-text-color);
}

.ant-upload-text-icon .anticon-paper-clip {
  color: var(--white-text-color);
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
  /* background-color: var(--grey-text-color); */
  background-color: var(--table-row-light-hover);
}

.ant-upload-span .ant-upload-text-icon span {
  color: white !important;
}

.ant-select-item-group {
  color: var(--white-text-color);
  font-style: italic;
}

.ant-select-clear,
.ant-select-clear:hover {
  color: #808080;
}

.ant-form-item-label > label {
  color: var(--white-text-color);
}

/* Global Modal css */
.ant-modal-content .modalScroller {
  height: 800px;
  overflow-y: scroll;
  padding: 2px;
}

.ant-modal-content .modalScroller::-webkit-scrollbar {
  display: block !important;
  width: 13px !important;
  height: 13px !important;
}

.ant-modal-content .modalScroller::-webkit-scrollbar-track {
  background: #888 !important;
  border-radius: 4px !important;
  /* color of the tracking area */
}

.ant-modal-content .modalScroller::-webkit-scrollbar-thumb {
  background: #444 !important;
  border-radius: 4px !important;
}

.ant-modal-content .modalScroller::-webkit-scrollbar-thumb:hover {
  background: #333 !important;
}

.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: inherit;
}

.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error input:not([disabled]) {
  border-color: #eb4d52 !important;
}

/*------------ antd steps styles ------------*/

.ant-steps-item-title {
  color: var(--white-text-color) !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: white;
  border: 2px solid var(--green-color);
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon {
  color: var(--green-color);
  font-weight: bold;
  top: -1.5px !important;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background: var(--grey-background-color);
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: var(--white-text-color);
  font-weight: bold;
  top: -1px;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background: var(--green-color);
  border: 2px solid var(--green-color);
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--white-text-color);
  top: -1.5px;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--green-color);
}

.ant-collapse-content .ant-descriptions-header .ant-descriptions-title {
  color: white;
}

/*------------ antd steps styles END ------------*/

.ant-select-item-option-state > .anticon-check > svg {
  color: var(--primary-color);
}

.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 10px;
}

.ant-upload.ant-upload-drag {
  /* border: 1px dashed rgba(94, 94, 94, 1) !important; */
  border: none;
}

.ant-upload-text-or {
  font-size: 12px !important;
  font-family: var(--font-normal) !important;
  font-weight: 400 !important;
}

.ant-checkbox-inner {
  background-color: unset !important;
  border-color: var(--primary-color) !important;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  border-color: var(--primary-color) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color) !important;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: white;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 21px 0 !important;
}

.ant-input {
  padding-left: 12px !important;
}
/* ant-input is disabled */
/* .ant-input-disabled {
  background-color: var(--btn-disabled-color) !important;
} */

.ant-radio-disabled + span {
  color: #fff !important;
}

/* ant Divider */
.ant-divider-horizontal.ant-divider-with-text {
  /* color: #c6c6c6; */
  color: var(--white-color);
  font-size: 12px;
}

.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  /* Line style */
  border-top-color: #555770;
  /* opacity: 0.5; */
}

.ant-tooltip {
  color: #30324a;
}

.ant-tooltip-arrow-content {
  background-color: #30324a;
}

.ant-tooltip-content {
  background: #30324a;
  border-radius: var(--border-radius);
}

.ant-tooltip-inner {
  background-color: #30324a;
  box-shadow: 0px 16px 24px 0px #00000052, 0px 2px 8px 0px #0000000a;
}

.ant-dropdown-menu {
  background-color: var(--dropdown-bg-color) !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-dropdown-menu-item {
  color: var(--white-text-color) !important;
}

.ant-checkbox-inner {
  border: 1px solid var(--primary-color);
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  background-color: var(--dropdown-select-color);
}

.ant-table-filter-dropdown-btns {
  background-color: var(--dropdown-bg-color);
  border-top: 1px solid grey;
}

.ant-btn-primary,
.ant-btn-primary:hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.ant-btn-link[disabled] {
  color: var(--grey-text-color);
}

.ant-btn-link[disabled]:hover {
  color: var(--grey-text-color);
}

.ant-collapse .ant-collapse-header {
  background: #32334d !important;
}
.ant-collapse-item .ant-collapse-content > .ant-collapse-content-box {
  background: #32334d !important;
}

.ant-collapse-item .ant-collapse-content > .ant-collapse-content-box {
  background: #32334d !important;
}

.expanded-icon {
  position: absolute;
  right: -10px;
  z-index: 999;
  width: 20px;
}
.collapsed-icon {
  position: absolute;
  right: -10px;
  z-index: 999;
  width: 20px;
}
.diagram-component {
  width: 100%;
  background-color: #29293d;
  border-color: transparent;
  align-items: center;
  height: 70vh;
}

.ant-tag {
  border-radius: 10px;
  color: white !important;
  background-color: var(--dropdown-select-color) !important;
  border: 1px solid var(--dropdown-select-color) !important;
  padding: 2px 7px !important;
  line-height: unset !important;

  .ant-tag-close-icon {
    color: white !important;
  }
}
.overview-component {
  z-index: 99;
  position: absolute;
  width: 200px;
  height: 100px;
  background-color: var(--dropdown-select-color);
  border: 1px solid black;
}
